/* .navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #eceff1;
}

.navbar__link {
  margin: 1em;
  font-size: 2em;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: .3em;
  text-indent: .3em;
  color: #181818;
  border-bottom: 3px solid transparent;
}

.navbar__link:hover {
  transform: translateY(-2px);
} */

ul.nav_blk li a.navbar__link--active {
  color: #02aff1;
  transition: border-bottom .5s ease-in-out;
}
