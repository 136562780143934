@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Libre+Franklin:200,300,400,500,600,700,800,900|Montserrat:200,300,400,500,600,700,800,900&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@charset "utf-8";
/*Start: Reset CSS*/
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
hr,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
:focus {
  outline: 0;
}
em {
  font-style: italic;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
a:active,
a:focus,
a:hover,
button:focus,
button:active,
button:hover {
  outline: 0;
  text-decoration: none;
}
html {
  height: 100%;
}
html[xmlns] .clearfix {
  display: block;
}
input:disabled {
  cursor: not-allowed;
}
/*END: Reset CSS*/

/* @import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap'); */

body {
  font-family: "Libre Franklin", sans-serif;
  font-weight: 400 !important;
  font-size: 14px;
  background: rgb(255, 255, 255);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(240, 245, 248, 1)),
    color-stop(15%, rgba(246, 246, 246, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(240, 245, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgba(240, 245, 248, 1)),
    to(rgba(255, 255, 255, 1))
  );
  background: linear-gradient(
    to bottom,
    rgba(240, 245, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f5f8', endColorstr='#ffffff', GradientType=0 );
  color: #051c39;
  background-repeat: no-repeat;
}

/* font-family: 'Montserrat', sans-serif;
font-family: 'Libre Franklin', sans-serif; */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

p {
  color: #000;
}
/* .VideoLabelsTab { min-height: 500px; color:#fff; font-size:15px;} */
.VideoLabelsTab .Progress {
  width: 100%;
  height: 5px;
  margin: 5px 15px 15px;
}
.TabContent {
  height: 500;
  overflow-y: scroll;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); /* for IE 9 */
  -webkit-transform: translate(-50%, -50%); /* for Safari */
}
img {
  max-width: 100%;
}
.logo > img {
  max-height: 40px;
}
.App {
  min-height: 100vh;
}
.App > .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
}

.Home {
  height: 100%;
  position: relative;
  z-index: 0;
}
.home {
  width: 100%;
}
.pos-r {
  position: relative;
}
/* ----------------------- krunal */
.btn.btn-outline-primary:hover {
  background-color: #4285f4 !important;
  color: #fff !important;
  font-weight: 400;
}
header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #f0f5f7;
  z-index: 2;
}
.logoSvg {
  height: 40px;
}
.main-nav {
  padding-top: 5px;
  padding-bottom: 5px;
}
ul.nav_blk {
  padding: 0;
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;
}
.logout_btn,
ul.nav_blk li a {
  padding: 7px 15px;
  text-transform: uppercase;
  color: #000;
  font-size: 14px;
  position: relative;
  display: block;
  font-weight: 500;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
}
ul.nav_blk li a.active,
ul.nav_blk li a:hover {
  color: #02aff1;
}
.hamurgar {
  display: flex;
  align-items: center;
  padding-left: 14px;
}
.topVideo {
  width: 100%;
}

.video {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
}
.video video {
  width: 100%;
  display: flex;
}
.listVideo .video img {
  display: block;
}

.tvDetails h3,
.vDetails h3 {
  margin-top: 0;
  text-align: left;
  font-weight: 500;
  font-size: 20px;
  max-width: 100%;
  margin-bottom: 10px;
}
.lvDetails h3 {
  margin-top: 5px;
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  max-width: 100%;
  margin-bottom: 5px;
}

.lvDetails {
  position: relative;
  width: 55%;
  padding-left: 15px;
}
.lvDetails .tagBlk {
  display: none;
}
.lvVideo {
  width: 45%;
}

.analyzeHover {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(144, 169, 241, 0.37);
  top: 0;
  left: 0;
  overflow: hidden;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
}
.video:hover .analyzeHover {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
.badge-theam {
  background-color: #02aff1 !important;
  color: #fff !important;
  font-weight: 400;
  margin-right: 5px;
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: 0.7px;
  margin-bottom: 5px;
  padding: 0.25em 0.8em;
  border-radius: 3px;
  box-shadow: none;
  font-family: "Montserrat", sans-serif;
}
.tvTextDetails,
.lvTextDetails {
  color: #909090;
  font-size: 14px;
  margin-bottom: 10px;
}
.lvTextDetails {
  overflow: hidden;
  max-height: 40px;
  max-width: 100%;
}
.lvAction .btn-BlueBorder {
  font-size: 11px;
}
.listVideo .video {
  width: 100%;
}
.analyzeHover .btn svg,
.playOption .btn svg {
  margin-top: -3px;
}

.pageTitle {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.pageTitle h2 {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 14px;
  color: #000000;
}
.logout_btn {
  padding: 6px 15px;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.6px;
}
.btn-Blue {
  background-color: #000;
  color: #fff;
  box-shadow: none;
  border-radius: 30px;
  font-family: "Montserrat", sans-serif;
}
.btn-Blue:hover {
  color: #fff !important;
}
.btn-BlueBorder {
  background-color: #fff;
  color: #02aff1;
  box-shadow: none;
  border-radius: 30px;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #02aff1;
}
.btn-BlueBorder:hover {
  color: #000 !important;
  border-color: #000;
}
.lvListing {
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
}

/* ---------------------- tab */

.Tab {
  background: #fff;
  padding: 13px 10px 10px;
  border-radius: 20px;
  box-shadow: 0 7px 5px -7px #d8d8d8;
  height: calc(100vh - 200px);
  min-height: 400px;
}
.tab-content {
  height: calc(100% - 40px);
}
.tab-content .tab-pane {
  height: 100%;
}

.videoTextTab,
.videoLabelsTab,
.ApiResponseTab {
  width: 100%;
  overflow-x: hidden;
  padding: 0px 0;
  max-height: 100%;
  overflow-y: auto;
  font-size: 13px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(225, 225, 225, 1);
  background-color: #ffffff;
}
::-webkit-scrollbar {
  width: 0px;
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
  background-color: #8f58e0;
}
.videoTextTab ul li {
  display: flex;
  flex-flow: row;
  padding: 2px;
  border-bottom: 1px solid #f5f5f5;
}

.videoTextTab ul li.liHead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ddd;
  padding: 5px;
  background: #fff;
  font-weight: 500;
  color: #888;
}
.videoTextTab ul li > span {
  width: 50%;
  text-transform: capitalize;
}
.timeRow {
  padding: 4px 0;
}
.vidTimeStart {
  width: 40%;
}

.vidTimeEnd {
  width: 40%;
}

.vidTimePlay {
  width: 20%;
}
.playIcon svg {
  height: 21px;
}
.playIcon {
  display: block;
}
.playIcon svg path {
  fill: #02aff1;
}
.playIcon:hover svg path {
  fill: #000;
}
.nav-tabs {
  border: 0;
  margin-bottom: 10px;
}

.nav-tabs .nav-item {
  margin: 0;
  padding: 0 5px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #02aff1;
  color: #ffffff;
}

.nav-tabs .nav-link {
  border: 0;
  border-radius: 20px;
}

.nav-tabs .nav-link {
  background-color: #f5f5f5;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
}
.nav-tabs .nav-link:hover {
  background-color: #eeeeee;
}
/* .nav-tabs .nav-link.active:hover {
    background: #8f58e0;color: #fff;    
} */
.videoLabelsTabList {
  display: flex;
  flex-direction: column;
  padding: 6px 10px;
}
.vtbStatus {
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
}
.vtbText,
.nuText {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
}
.progress {
  height: 0.5rem !important;
}
.progress-bar {
  height: 0.5rem !important;
  background: #02aff1 !important;
  background: -webkit-linear-gradient(
    right,
    rgb(200, 218, 241) 0%,
    rgb(2, 175, 241) 100%
  ) !important;
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(rgb(200, 218, 241)),
    to(rgb(2, 175, 241))
  ) !important;
  background: -webkit-linear-gradient(
    left,
    rgb(200, 218, 241) 0%,
    rgb(2, 175, 241) 100%
  ) !important;
  background: linear-gradient(
    to right,
    rgb(200, 218, 241) 0%,
    rgb(2, 175, 241) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfd7f3', endColorstr='#5b37bf',GradientType=1 ) !important;
  border-radius: 0 5px 5px 0;
}
.react-json-view {
  /* background-color: rgb(0, 43, 54) !important; */
  padding: 10px;
}
.ApiResponseTab {
  border: 1px solid #eee;
}
/* ---------------------- tab  end*/

.vDetails h3 {
  margin-bottom: 5px;
}

.vDetails {
  padding: 20px 0;
}
.btnrow {
  display: flex;
}
/* --------------------------------------------------- Login */
.loginMain_blk {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.loginMain_blk h6 {
  color: #000;
}
.loginMain_blk .third input {
  background-color: rgb(255, 255, 255);
  height: 42px;
  color: #333 !important;
  margin: 0;
  padding: 0 15px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 3px;
  /* border-radius: 9px;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.52); */
}
.loginMain_blk .third .md-form label.active {
  -webkit-transform: translateY(-27px) scale(0.8);
          transform: translateY(-27px) scale(0.8);
}
.loginMain_blk .third .md-form label {
  color: #000000;
  left: 10px;
}
.loginMain_blk .third .md-form {
  margin-top: 2rem;
}

.colBg {
  position: relative;
  /* background-color: #92a0f0; */
  overflow: hidden;
}

.colBg img {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
.loginLogoSvg {
  height: 70px;
}

.colCont {
  max-width: 600px;
  /*background-color: #fff; */
  /* overflow: hidden; */
}
@media (max-width: 1395px) {
  .colCont {
    max-width: 350px;
    min-width: 350px;
  }
}
.loginBoxArea {
  background-color: rgba(255, 255, 255, 0.8);
  width: 350px;
  padding: 30px 15px;
  z-index: 4;
  border-radius: 7px;
}

.bgSection {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;
  /* display: none; */
}
.bgSection .bsSec_2 {
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(rgb(200, 218, 241)),
    to(rgb(2, 175, 241))
  ) !important;
  background: -webkit-linear-gradient(
    left,
    rgb(200, 218, 241) 0%,
    rgb(2, 175, 241) 100%
  ) !important;
  background: linear-gradient(
    to right,
    rgb(200, 218, 241) 0%,
    rgb(2, 175, 241) 100%
  ) !important;
  background-size: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0.9;
  z-index: 2;
  text-align: center;
}
.bgSection .bsSec_1 {
  /* background-image:url(../images/18300.jpg); */
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0.9;
  z-index: 2;
  text-align: center;
  background-position: left;
}
.bgSection .bsSec_1 svg {
  height: 100%;
  margin: auto;
  max-width: 71%;
}

.bgSection .bsSec_4 {
  width: 123px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  background-repeat: no-repeat;
  background-position: right center;
}
.bgSection .bsSec_4 svg {
  height: 100vh;
  float: right;
}
.fourth {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  margin: auto;
}
.fourth p {
  color: #fff;
}
.triangel {
  position: absolute;
  width: 120%;
  height: 126%;
  background: #9061e2;
  border-radius: 20px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.tgl_1 {
  right: -60%;
  bottom: 8%;
  opacity: 0.2;
}
.tgl_2 {
  right: -50%;
  top: -7%;
  opacity: 1;
}
/* --------------------------------------------------- Login */

/* --------------------------------------------------- Upload */
.uploadTopText {
  background-color: #fff;
  width: 100%;
  padding: 40px 0;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  line-height: 1.7;
}
.uploadVideoPageMain .videoDetailPage {
  height: auto;
}

.uploadVideoPageMain .videoDetailPage .Tab {
  height: auto;
}
.uploadVideoPageMain .videoDetailPage .tab-content {
  height: 400px;
}
.UploadVideo_blk {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadVideoSec_blk {
  display: flex;
  flex-direction: row;
  align-items: start;
  padding-bottom: 40px;
  padding-top: 40px;
}
.upload {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 1170px;
  margin: 0 auto;
}
.upload p {
  color: #909090;
}

.vUpload {
  border: 1px dashed #aaa;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2vw;
  border-radius: 7px;
}
.fileselect input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}
.uploadIcon svg path {
  fill: #e6e6e6;
}

.newupload {
  width: 100%;
}

.newupload .nuStatus {
  margin-top: 15px;
}
.dragText p {
  font-size: 21px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.dragText {
  padding-top: 10px;
}
.UploadpageTitle h2 {
  text-transform: uppercase;
  font-size: 21px;
  margin-bottom: 10px;
}
.Note h2 {
  /* text-transform: uppercase; */
  font-size: 15px;
  margin-bottom: 10px;
}
.uploadTitle h2 {
  text-transform: uppercase;
  font-size: 17px;
  margin-bottom: 0;
}
.uploadIcon svg {
  height: 100px;
}
.videoDetailPage .upload.vUpload {
  padding: 1.7vw;
}
.videoDetailPage .dragText p {
  font-size: 18px;
}
.uploadContent {
  width: 50%;
  padding-right: 20px;
  /*padding-left: 15px; */
}

.uploadVideoSec {
  width: 50%;
  /* padding-right: 15px;*/
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* .uploadContent > p{
    color: #4a4a4a;
    margin-bottom: 20px;
}
.uploadContent ol{
    padding-left: 17px;
    list-style-type: circle;
}
.uploadContent ol li ul{
    padding-left: 17px;
    list-style: lower-alpha;
}
.uploadContent li{
    padding: 5px 0;
} */
.uploadContent ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding-bottom: 25px; */
  position: relative;
  z-index: 1;
}

.listIcon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
}

.contList {
  width: calc(100% - 40px);
  padding-left: 15px;
  color: #626365;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

.listIcon_blk {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #02aff1;
  border: 3px solid #2993c5;
}

.listIcon_blk svg {
  fill: #fff;
  height: auto;
  width: 15px;
}

.bigIcon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
}

.uploadContent ul {
  position: relative;
}

.uploadContent ul:after {
  content: "";
  position: absolute;
  background: #2993c5;
  height: 85%;
  width: 3px;
  top: 6px;
  left: 19px;
  z-index: 0;
}
.uploadContent ul li.subCont .contList {
  font-weight: 400;
}
/* --------------------------------------------------- Upload */

.listVideo {
  height: 85vh;
  overflow-y: auto;
  min-height: 600px;
}
.contactPopup .modal-header {
  padding: 10px 3px !important;
  border: 0;
  justify-content: unset;
  align-items: unset;
  display: block;
}
.contactPopup .modal-header .close {
  float: none;
  position: absolute;
  right: 15px;
  top: 15px;
}
.contactPopup .modal-header .close > span {
  background: #02aff1;
  line-height: 1.1;
  height: 20px;
  display: block;
  width: 20px;
  text-shadow: none;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
}
.contactPopup .modal-dialog {
  max-width: 500px;
  margin: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}
.modal.contactPopup {
  background: rgba(49, 49, 49, 0.5);
}
.contactPopup .modal-body {
  padding-bottom: 20px;
  padding-top: 20px;
}

.contactPopup .modal-body .modal-title.h4 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #02aff1;
}

.contactPopup .modal-body .form-group {
  padding: 0 11%;
}

.contactPopup .modal-body .form-group input {
  background: transparent;
  border: 0;
  font-size: 13px;
  border-radius: 0;
  height: 41px;
  letter-spacing: 0.6px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  border-bottom: 2px solid #e4e0e0;
}

.contactPopup .modal-body .form-group button {
  background-color: #02aff1 !important;
  color: #fff;
  box-shadow: none;
  width: auto;
  font-family: "Montserrat", sans-serif;
  margin: 0 auto;
  padding: 10px 35px;
  border-radius: 44px;
  display: block;
}

.contactPopup .modal-body .form-group button:hover {
  background-color: #000000 !important;
}
.logoBg {
  width: 130px;
  margin: auto;
}
.modelPop {
  width: 100%;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -moz-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;
}

.modelForm {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.bannerModel {
  width: 40%;
  overflow: hidden;
  background: #f5f5f5;
  padding: 25px;
}
.bannerModel img:first-child {
  margin-bottom: 15px;
}
/* ------------------------- home */
.banner_blk {
  /* height: 100vh; */
}
.banner_blk img {
  width: 100%;
}
.bannerContant {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.bCont {
  padding-bottom: 5vw;
}
.bcontTxt {
  font-size: 36px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2;
  margin-bottom: 1vw;
  margin-top: 1vw;
}
.bLogo {
  height: 100px;
  width: auto !important;
}
.bCont .btn-BlueBorder {
  color: #fff;
  border-color: #02aff1;
  background: #02aff1;
  position: relative;
}
.bCont .btn-OrangeBorder {
  color: #fff;
  border-color: #cb5e00;
  background: #cb5e00;
  position: relative;
  border-radius: 30px;
}
.bCont .btn-BlueBorder:hover {
  color: #000;
  border-color: #fff;
  background: #fff;
}

.bgGray {
  background-color: #f8f8f8;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.bgWhite {
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.homepage .container h3 {
  margin-bottom: 30px;
  font-size: 22px;
  color: #02aff1 !important;
  font-weight: 600;
}
.homepage .container h4 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #000 !important;
  font-weight: 600;
}
.homepage .container .img {
  /* border: 1px solid #efefef;
    background: #efefef; */
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.homepage .container .img img {
  max-height: 100%;
}
.homepage .container .pTxt {
  padding: 30px;
  text-align: center;
  line-height: 1.7;
  letter-spacing: 0.5px;
}
.homepage .container.bgGray {
  background: #f8f8f8;
}

.idealSolution p {
  margin: 15px 30px 30px;
  text-align: center;
}

.idealSolution {
  text-align: center;
}

.keyFeatures li {
  position: relative;
  margin-bottom: 5px;
}

.keyFeatures li:before {
  content: "";
  width: 10px;
  height: 10px;
  background: #02aff1;
  display: inline-block;
  margin-right: 15px;
}

.capabilities p {
  margin-bottom: 30px;
}

.modal.show .modal-dialog .modal-content {
  border-radius: 1.3rem;
}
/* ------------------------- home */

@media (max-width: 1200px) {
  .upload {
    width: 100%;
    padding: 0 15px;
  }
  .hamurgar {
    padding-left: 0;
  }
}
@media (max-width: 991px) {
  .logout_btn,
  ul.nav_blk li a {
    font-size: 11px;
    padding: 7px;
  }
  .uploadVideoSec_blk {
    flex-direction: column;
  }
  .uploadContent,
  .uploadVideoSec {
    width: 100%;
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
  .bcontTxt {
    font-size: 25px;
    margin-bottom: 2vw;
    margin-top: 2vw;
  }

  .bLogo {
    height: 50px;
    width: auto !important;
  }
}
@media (max-width: 810px) {
  .modelPop {
    flex-direction: column;
  }

  .bannerModel {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .modelForm {
    width: 100%;
  }

  .bannerModel img {
    width: 35%;
  }
  .modal.show .modal-dialog {
    max-width: 80%;
  }
}
@media (max-width: 767px) {
  .logout_btn,
  ul.nav_blk li a {
    font-size: 12px;
    padding: 7px 10px;
  }
  .hamurgar {
    justify-content: center;
    width: 100%;
    padding-top: 15px;
  }
  .lvVideo,
  .lvDetails {
    width: 100%;
  }
  .lvDetails {
    padding: 0;
    margin-bottom: 20px;
  }
  .listVideo {
    height: auto;
    margin-top: 25px;
  }
  .UploadVideo_blk {
    padding-top: 20px;
  }
  .homepage .container h3 {
    margin-bottom: 30px;
    font-size: 17px;
  }
  .uploadContent ul:after {
    height: 87%;
  }
  .uploadContent ul li {
    align-items: start;
  }
  .vUpload {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .tvDetails h3,
  .vDetails h3,
  .UploadpageTitle h2 {
    font-size: 16px;
  }
  .uploadIcon svg {
    height: 95px;
  }
  .dragText p,
  .tvTextDetails,
  .lvTextDetails {
    font-size: 15px;
  }
  .homepage .container .pTxt {
    padding-left: 0;
    padding-right: 0;
  }
  .bcontTxt {
    font-size: 15px;
  }

  .pcBanner {
    display: none !important;
  }
}
@media (max-width: 480px) {
  body {
    font-size: 12px;
  }
  .tvDetails h3,
  .vDetails h3,
  .UploadpageTitle h2 {
    font-size: 13px;
  }
  .dragText p,
  .tvTextDetails,
  .lvTextDetails,
  .videoTextTab,
  .videoLabelsTab,
  .ApiResponseTab {
    font-size: 12px;
  }
  .contactPopup .modal-body .form-group {
    padding: 0 0%;
  }

  .modal.show .modal-dialog {
    max-width: 350px;
  }
  .contactPopup .modal-body .modal-title.h4 {
    font-size: 13px;
  }
  .modelForm {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .uploadContent ul:after {
    height: 90%;
  }
}

@media (max-width: 359px) {
  .logout_btn,
  ul.nav_blk li a {
    font-size: 11px;
  }
}
@media (min-width: 641px) {
  .homepage .container {
    padding-top: 50px;
    padding: 50px;
  }
  .homepage .container .row {
    margin-bottom: 30px;
  }
  .mobBanner {
    display: none !important;
  }
}

/* .navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #eceff1;
}

.navbar__link {
  margin: 1em;
  font-size: 2em;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: .3em;
  text-indent: .3em;
  color: #181818;
  border-bottom: 3px solid transparent;
}

.navbar__link:hover {
  transform: translateY(-2px);
} */

ul.nav_blk li a.navbar__link--active {
  color: #02aff1;
  -webkit-transition: border-bottom .5s ease-in-out;
  transition: border-bottom .5s ease-in-out;
}

